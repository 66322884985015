<template>
  <div class="save-form-page">
    <h6 class="ym-title">
      <el-link class="ym-title-link" href="#/listBroods">育雏信息 <i class="el-icon-arrow-right"></i> </el-link>
      <span>{{ title }}</span>
    </h6>
    <div class="ym-panel-general">
      <el-form :model="form" :rules="rules" ref="form" label-width="282px">
        <el-form-item prop="productId" label="产品名称：">
          <select-page
            label="productName"
            value="productId"
            apiFile="currency"
            apiName="listProducts"
            placeholder="请选择产品名称"
            filter="productName"
            :selected.sync="productId"
            :params="productParams"
            @change="onProductChange"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="afcBatchId" label="产品批次编号：">
          <el-input
            v-if="id"
            v-model="form.batchNum"
            maxlength="50"
            disabled
            size="medium"
          ></el-input>
          <select-page
            v-else
            label="batchNum"
            value="afcBatchId"
            apiFile="currency"
            apiName="listAfcBatchs"
            placeholder="请选择产品批次编号"
            filter="batchNum"
            :selected.sync="afcBatchId"
            :params="batchParams"
            @change="onBatchChange"
            :disabled="isOpen"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="sourceCompanyId" label="种源提供商：">
          <select-page
            label="companyName"
            value="companyId"
            apiFile="currency"
            apiName="listCompanys"
            placeholder="请选择种源提供商"
            filter="companyName"
            :selected.sync="companyId"
            :params="companyParams"
            @change="onCompanyChange"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="intakeHennery" label="入住鸡舍：">
          <el-input
            placeholder="请输入入住鸡舍"
            v-model="form.intakeHennery"
            maxlength="50"
            clearable
            size="medium"
          ></el-input>
        </el-form-item>

        <el-form-item prop="intakeQty" label="入住数量：">
          <el-input
            placeholder="请输入入住数量"
            v-model.number="form.intakeQty"
            maxlength="10"
            type="number"
            clearable
            size="medium"
          ></el-input>
        </el-form-item>

        <el-form-item prop="intakeTime" label="入住日期：">
          <el-date-picker
            v-model="form.intakeTime"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="请选择入住日期"
            size="medium"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="sourceIntroduce" label="种源介绍：">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="form.sourceIntroduce"
            maxlength="50"
            show-word-limit
            size="medium"
            rows="3"
          >
          </el-input>
        </el-form-item>

        <el-form-item prop="files" label="鸡舍图片：">
          <uploadFile
            :limit="1"
            :sizeLimit="1024 * 1"
            :businessType="businessType"
            :file-list="fileList"
            @onSuccess="handleAvatarSuccess"
            typeErrorMsg="请上传jpg、jpeg、bmp、png格式的文件"
            @onRemove="onRemove"
            :file-list-type="listType"
          >
            <el-button size="small" plain>
              <i class="iconfont iconshangchuan file-icon"></i>
              上传图片</el-button
            >
            <div slot="tip" class="el-upload__tip">
              *支持最多上传1个附件，单个附件最大1MB，尺寸为320*320，格式为jpg/jpeg/bmp/png
            </div>
          </uploadFile>
        </el-form-item>

        <el-form-item>
          <el-button @click="goBack" size="medium" round plain>返回</el-button>
          <el-button type="primary" @click="submit" size="medium" round
            >确认提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '添加育雏信息',
      productId: '',
      afcBatchId: '',
      companyId: '',
      productParams: { enableFlag: 1 },
      batchParams: { inOutFlag: 1, enableFlag: 1, tacheTypeId: 1 },
      companyParams: {
        enableFlag: 1,
        companyTypeId: 101
      },
      businessType: 'brood', // 业务类型（育雏信息文件）
      listType: 'picture', // 显示列表类型
      fileList: [],
      isOpen: false, // 默认可选择产品批次编号
      // 表单对象
      form: {
        productId: '', // 产品名称ID
        productName: '', // 产品名称
        afcBatchId: '', // 产品批次编号ID
        batchNum: '', // 产品批次编号
        sourceCompanyId: '', // 种源提供商ID
        sourceCompanyName: '', // 种源提供商
        intakeHennery: '', // 入住鸡舍
        intakeQty: '', // 入住数量
        intakeTime: '', // 入住日期
        sourceIntroduce: '', // 种源介绍
        files: [] // 图片上传
      },
      rules: {
        productId: [
          { required: true, message: '请选择产品名称', trigger: 'change' }
        ],
        afcBatchId: [
          { required: true, message: '请选择产品批次编号', trigger: 'change' }
        ],
        sourceCompanyId: [
          { required: true, message: '请选择种源提供商', trigger: 'change' }
        ],
        intakeHennery: [
          {
            type: 'string',
            required: true,
            message: '请填写入住鸡舍',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 50,
            message: '长度在 3 到 50 个字符',
            trigger: 'blur'
          }
        ],
        intakeQty: [
          {
            type: 'number',
            required: true,
            message: '请填写入住数量',
            trigger: 'blur'
          }
        ],
        intakeTime: [
          {
            required: true,
            message: '请选择入住日期',
            trigger: 'change'
          }
        ],
        sourceIntroduce: [
          {
            type: 'string',
            required: true,
            message: '请填写种源介绍',
            trigger: 'blur'
          }
        ],
        files: [
          {
            type: 'array',
            required: true,
            message: '请上传鸡舍图片',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  watch: {},
  created() {
    if (this.id) {
      console.log('id:', this.id)
      this.title = '修改育雏信息'
      this.batchParams.inOutFlag = 2
      this.get()
      this.isOpen = true // 关闭产品批次选择
    }
  },
  methods: {
    goBack() {
      this.$router.push('/listBroods')
    },

    // 选择产品名称
    onProductChange(val) {
      if (!val) {
        this.form.productId = ''
        this.form.productName = ''
      } else {
        this.form.productId = val.productId
        this.form.productName = val.productName
      }
    },

    // 选择产品批次编号
    onBatchChange(val) {
      if (!val) {
        this.form.afcBatchId = ''
        this.form.batchNum = ''
      } else {
        this.form.afcBatchId = val.afcBatchId
        this.form.batchNum = val.batchNum
      }
    },

    // 选择种源提供商
    onCompanyChange(val) {
      if (!val) {
        this.form.sourceCompanyId = ''
        this.form.sourceCompanyName = ''
      } else {
        this.form.sourceCompanyId = val.companyId
        this.form.sourceCompanyName = val.companyName
      }
    },

    submit() {
      this.$refs['form'].validate(async(valid) => {
        if (!valid) return
        else {
          if (this.id) {
            this.edit()
          } else {
            this.add()
          }
        }
      })
    },

    // 查询
    async get() {
      const params = {
        afcBatchInId: this.id
      }
      const { data } = await this.$http.broodInfor.getBrood(params)
      if (data.code !== '0') return this.$message.error(data.msg || '查询失败')
      // 回显表单
      console.log('查询数据：', data.data)
      this.form = data.data
      this.companyId = data.data.sourceCompanyId
      this.afcBatchId = data.data.afcBatchId
      this.productId = data.data.productId
      const file = {
        name: data.data.files[0].originalName,
        url: data.data.files[0].fileUrl
      }
      this.fileList.push(file)
      // console.log('fileList:', this.fileList[0])
    },
    // 添加
    async add() {
      const params = { ...this.form }
      const { data } = await this.$http.broodInfor.saveBrood(params)
      if (data.code !== '0') return this.$message.error(data.msg || '新增失败')
      this.$message.success('新增成功')
      this.goBack()
    },
    // 修改
    async edit() {
      const params = { ...this.form }
      const { data } = await this.$http.broodInfor.updateBrood(params)
      if (data.code !== '0') return this.$message.error(data.msg || '修改失败')
      this.$message.success('修改成功')
      this.goBack()
    },

    /** 文件上传相关 */
    handleAvatarSuccess(res, file) {
      if (res.code === '0') {
        this.form.files = res.data
        console.log('files:', res.data)
        this.$refs.form.validateField('files')
        // this.imgUrl = URL.createObjectURL(file.raw)
        // this.fileList = [{ name: res.data[0].originalName, fileUrl: res.data[0].file }]
      } else {
        this.$message.error(res.msg || '添加失败')
      }
    },
    onRemove() {
      console.log('onRemove')
      this.form.files = ''
    }
  }
}
</script>

<style lang="scss">
.save-form-page {
  .el-form {
    margin-top: 40px;
  }
  .warning {
    color: #e6a23c !important;
  }

  .el-input {
    width: 400px;
  }

  .el-textarea {
    width: 400px;
  }

  .upload-demo {
    width: 600px;
  }

  //按钮图标样式调整
  .iconfont {
    font-family: 'iconfont' !important;
    font-size: 14px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .file-icon {
    font-size: 10px;
    margin-right: 2px;
  }
}
</style>
